@import './src/app/variables';
/* Consumer navbar, professional navbar */
.smallLogo h3 {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  // color: var(--light-blue);
  color: $primary;
}

.smallLogo p {
  font-size: 0.75rem;
  color: lighten($color: $primary, $amount: 5);
  font-weight: 400;
  text-align: center;
  font-style: italic;
}

/* Home page navbar */
.largeLogo h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  color: lighten($color: $primary, $amount: 5);
}
.largeLogo p {
  font-size: 0.85rem;
  font-style: italic;
  color: lighten($color: $primary, $amount: 5);
  font-weight: 500;
  text-align: center;
}

.profileImage:hover {
  cursor: pointer;
}

.navLink,
.navLink:hover {
  color: $primary !important;
  font-weight: 800;
}

.dropdownMenu {
  display: none !important;
}

.dropdownContainer:hover .dropdownMenu {
  display: block !important;
}

.dropdownMenu {
  min-width: 220px !important;
}

.dropdownItem {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.dropdownItem:hover {
  background: var(--bs-dropdown-link-hover-bg);
}

.dropdownItem:active {
  color: black !important;
  background: none !important;
}
